<template>
  <div>
    <!-- Partners -->
    <b-row align-h="between">
      <b-col><h2>{{partnersTitle}}</h2></b-col>
    </b-row>
    <b-row class="match-height">
      <b-col xs="12" sm="6" md="4" xxl="3" v-for="partner in partners" :key="partner.id">
        <partner-card :data="partner" />
      </b-col>
    </b-row>
    <!-- Partners End-->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from "bootstrap-vue";
import PartnerCard from "./PartnerCard";
import store from '@/store/index'

export default {
  metaInfo() {
    return {
      title: this.partnersTitle
    }
  },
  components: {
    BRow,
    BCol,
    PartnerCard,
  },
  data() {
    return {
      partnersTitle: store.getters['navigation/getTitle']('partners'),
      partners: store.getters['partners/all']
    }
  },
}
</script>

<style>
</style>
