<template>
  <b-card no-body>
    <b-card-body v-b-tooltip.hover :title="data.title" class="d-flex flex-column justify-content-center align-items-center" style="min-height:200px">
        <b-img :src="data.logo" height="auto" width="150px" :alt="data.title" />
        <div class="w-100 d-flex flex-row justify-content-between mt-auto">
          <b-badge variant="primary">{{data.category}}</b-badge>
          <div>
            <b-link v-if="data.link" :href="data.link" target="_blank" rel="noopener"><font-awesome-icon :icon="['fal', 'globe']" size="lg"/></b-link>&nbsp;
            <b-link v-if="data.booth" :href="data.booth" class=""><font-awesome-icon :icon="['fal', 'person-booth']" size="lg"/></b-link>
          </div>
        </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BImg, BCardBody, BCardFooter, BLink, VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge, 
    BCard,
    BImg,
    BCardBody,
    BCardFooter,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped>
.booth::after {
  background-image: linear-gradient(360deg, rgba(0,0,0,0.07) 55%, rgba(0,0,0,0.66) 90%);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
</style>
